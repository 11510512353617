import { run } from '@cycle/run';
import { makeHTTPDriver } from '@cycle/http';
import { createCycleMiddleware } from 'redux-cycles';
import { createLogger } from 'redux-logger';
import main from '../cycles';
import { createBrowserHistory } from 'history';
import reducers from '../reducers';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';

const logger = createLogger({});

const middleWare = [];

const cycleMiddleware = createCycleMiddleware();

const { makeActionDriver, makeStateDriver } = cycleMiddleware;

middleWare.push(cycleMiddleware);

if (process.env.NODE_ENV === 'development') {
    middleWare.push(logger);
} else {
}

const persistConfig = {
    key: 'grafidRoot',
    storage,
    whiteList: ['applicationUser'],
};

 const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: middleWare,
    devTools: process.env.NODE_ENV !== 'production',
});

run(main, {
    ACTION: makeActionDriver(),
    STATE: makeStateDriver(),
    HTTP: makeHTTPDriver(),
});

export const  persistor = persistStore(store);

export const history = createBrowserHistory({basename: '/'});

export default store;
