import React, { useCallback } from 'react';
import {NotificationsList} from 'arag-ui-lib';
import { useDispatch, useSelector } from 'react-redux';
import messageProjections from '../../projections/message';
import * as actions from '../../actions';

const ApplicationMessages = (props) => {
    const dispatch = useDispatch();
    const removeMessageAction = useCallback(
        (payload) => dispatch(actions.removeMessage(payload)),
        [dispatch],
    );

    const handleMessageRemove = message => {
        removeMessageAction({uuid: message.key});
    };

    const applicationMessageList = useSelector(messageProjections.applicationMessageList(handleMessageRemove));

    return (
        <NotificationsList
            variant={'error'}
            position={'topCenter'}
            notifications={applicationMessageList}
            customStyle={{
                maxWidth: '900px',
                minWidth: '450px',
                whiteSpace: 'normal',
            }}
        />
    );
};

export default ApplicationMessages;