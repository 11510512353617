import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import * as actions from '../actions';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import HEADER_FUNCTIONS from '../utils/functions/headers';
import {history} from '../store/store';

const REQUEST_PRODUCTS = 'requestProducts';
const REQUEST_CREATE_PRODUCT = 'requestCreateProduct';
const REQUEST_PATCH_PRODUCT = 'requestPatchProduct';
const REQUEST_FETCH_PRODUCT = 'requestFetchProduct';
const REQUEST_DELETE_PRODUCT = 'requestDeleteProduct';

export function redirectAfterProductCreateSuccess(sources) {
    const action$ = sources.ACTION
        .filter(action => (
            action.type === ActionTypes.CREATE_NEW_PRODUCT_SUCCESS
            || action.type === ActionTypes.EDIT_PRODUCT_SUCCESS
        ))
        .map(action => {
            history.push('/products');
            return actions.redirectAfterProductCreateEditSuccess();
        });

    return {
        ACTION: action$,
    };
}

export function requestFetchProduct(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PRODUCT_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Product/ReadProduct/${action.payload}`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_FETCH_PRODUCT,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_PRODUCT)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchProductByidSuccess(response)
                : actions.fetchProductByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestDeleteProduct(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.DELETE_PRODUCT_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Product/DeleteProduct`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_DELETE_PRODUCT,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_DELETE_PRODUCT)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.deleteProductByidSuccess(response)
                : actions.deleteProductByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestPatchProduct(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.EDIT_PRODUCT)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Product/UpdateProduct`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PATCH_PRODUCT,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PATCH_PRODUCT)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.editProductSuccess(response)
                : actions.editProductFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestCreateProduct(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.CREATE_NEW_PRODUCT)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Product/CreateProduct`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_CREATE_PRODUCT,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_PRODUCT)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 201
                ? actions.createNewProductSuccess(response)
                : actions.createNewProductFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestProducts(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PRODUCTS)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Product/ReadAllProducts`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PRODUCTS,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PRODUCTS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchProductsSuccess(response)
                : actions.fetchProductsFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
