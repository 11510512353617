import React, {useCallback, useEffect} from 'react';
import {Form, Formik} from 'formik';
import FormikFieldWrapper from '../general/formikFieldWrapper/formikFieldWrapper';
import {Button, Heading, Loader} from 'arag-ui-lib';
import {useDispatch, useSelector} from 'react-redux';
import distributorsProjections from '../../projections/distributors';
import producerProjections from '../../projections/producers';
import * as actions from '../../actions';
import config from '../../utils/constants/config';
import GENERAL_FUNCTIONS from '../../utils/functions/functions';

const ProductsForm = (props) => {
    const {handleSubmit, title, submitLabel, formInitialValues = {}, disabledFieldKeys = [], loading} = props;
    const dispatch = useDispatch();
    const distributorsDropdownOptions = useSelector(distributorsProjections.distributorsDropdownOptions);
    const producersDropdownOptions = useSelector(producerProjections.producersDropdownOptions);

    const fetchDistributorsAction = useCallback(
        (payload) => dispatch(actions.fetchDistributors(payload)),
        [dispatch],
    );

    const fetchProducersAction = useCallback(
        (payload) => dispatch(actions.fetchProducers(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchDistributorsAction();
    }, []);

    useEffect(() => {
        fetchProducersAction();
    }, []);

    return (
        <div>
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
              <div>
                  <Heading variant="h2" title={title} />
                  <Form
                      className="form-horizontal"
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                  >
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Medical Name'}
                          variant={'text'}
                          inputProps={{
                              name: 'medicalName',
                              required: true,
                              disabled: disabledFieldKeys ?
                                  disabledFieldKeys.includes('medicalName') : false,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Brand Name'}
                          variant={'text'}
                          inputProps={{
                              name: 'brandName',
                              required: true,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Distributor'}
                          variant={'select'}
                          inputProps={{
                              required: true,
                              name: 'distributorId',
                              options: distributorsDropdownOptions,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Producer'}
                          variant={'select'}
                          inputProps={{
                              required: true,
                              name: 'producerId',
                              options: producersDropdownOptions,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Pxp'}
                          variant={'file'}
                          getInputProps={(formikBag) => ({
                              name: 'pxp',
                              label: formikBag.field.value && Array.isArray(formikBag.field.value)
                                  ? (
                                      formikBag.field.value.reduce((acc, curr) => curr.name, '' )
                                  )
                                  : 'Browse' ,
                              accepts: config.ACCEPTED_FILE_EXTENSIONS,
                              allowMultiple: false,
                              onDrop: async files => {
                                  const readFiles = await Promise
                                      .all(
                                          files
                                              .map(async file => {
                                                  const encodedFile = await GENERAL_FUNCTIONS.readFileAsBase64Async(file);
                                                  return {
                                                      name: file.name,
                                                      encodedFile: encodedFile,
                                                  };
                                              }),
                                      );
                                  formikBag.setFieldValue(formikBag.field.name, readFiles);
                              },
                          })}
                          inputProps={{
                              name: 'pxp',
                          }}
                      />
                      <div className={'form-group'}>
                          <div className={'col-sm-7 col-sm-offset-3'}>
                              <Button type="submit" variant="primary" label={submitLabel} arrowDirection="right" />
                              {loading && (
                                  <Loader variant={'inline'}/>
                              )}
                          </div>
                      </div>
                  </Form>
              </div>
            </Formik>
        </div>
    );
};

export default ProductsForm;