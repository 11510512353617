import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import _ from "lodash";

const INITIAL_STATE = {
    producers: {
        byId: {},
        allIds: [],
    },
    loading: false,
    posting: false,
};

const  fetchProducers = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const  fetchProducersSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('legalName', action.payload.body);

    return {
        ...state,
        loading: false,
        producers: {
            byId,
            allIds,
        },
    };
};

const  fetchProducersFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const createNewProducer = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const createNewProducerSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const createNewProducerFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};


const fetchProducerByid = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchProducerByidSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('legalName', [action.payload.body]);

    return {
        ...state,
        loading: false,
        producers: {
            byId: byId,
            allIds: allIds,
        },
    };
};

const fetchProducerByidFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const deleteProducerByid = (state, action) => {
    return {
        ...state,
    };
};

const deleteProducerByidSuccess = (state, action) => {
    const deletedBy = (
        action.payload.request
        && action.payload.request.send
        && action.payload.request.send.legalName
    ) ? action.payload.request.send.legalName : null;

    if(!deletedBy){
        return {
            ...state,
        };
    }
    const items = _.pickBy(state.producers.byId, e => e.legalName !== deletedBy);
    const allIds = state.producers.allIds.filter(e => e.legalName !== deletedBy);

    return {
        ...state,
        producers: {
            byId: items,
            allIds,
        },
    };
};

const deleteProducerByidFailed = (state, action) => {
    return {
        ...state,
    };
};

const editProducer = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const editProducerSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const editProducerFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};


const producers = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_PRODUCERS]: fetchProducers,
    [ActionTypes.FETCH_PRODUCERS_SUCCESS]: fetchProducersSuccess,
    [ActionTypes.FETCH_PRODUCERS_FAILED]: fetchProducersFailed,
    [ActionTypes.CREATE_NEW_PRODUCER]: createNewProducer,
    [ActionTypes.CREATE_NEW_PRODUCER_SUCCESS]: createNewProducerSuccess,
    [ActionTypes.CREATE_NEW_PRODUCER_FAILED]: createNewProducerFailed,
    [ActionTypes.FETCH_PRODUCER_BY_ID]: fetchProducerByid,
    [ActionTypes.FETCH_PRODUCER_BY_ID_SUCCESS]: fetchProducerByidSuccess,
    [ActionTypes.FETCH_PRODUCER_BY_ID_FAILED]: fetchProducerByidFailed,
    [ActionTypes.DELETE_PRODUCER_BY_ID]: deleteProducerByid,
    [ActionTypes.DELETE_PRODUCER_BY_ID_SUCCESS]: deleteProducerByidSuccess,
    [ActionTypes.DELETE_PRODUCER_BY_ID_FAILED]: deleteProducerByidFailed,
    [ActionTypes.EDIT_PRODUCER]: editProducer,
    [ActionTypes.EDIT_PRODUCER_SUCCESS]: editProducerSuccess,
    [ActionTypes.EDIT_PRODUCER_FAILED]: editProducerFailed,
});

export default producers;
