import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import ProducersForm from '../../components/producersForm/producersForm';

const CreateProducerContainer = (props) => {
    const {} = props;
    const posting = useSelector(state => state.producers.posting);

    const dispatch = useDispatch();

    const createProducerAction = useCallback(
        (payload) => dispatch(actions.createNewProducer(payload)),
        [dispatch],
    );


    const handleSubmit = values => {
        createProducerAction(values);
    };

    return (
        <div>
            <ProducersForm
                title={'Create New Producer'}
                submitLabel={'Create'}
                handleSubmit={handleSubmit}
                loading={posting}
            />
        </div>
    );
};

export default CreateProducerContainer;