import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import _ from 'lodash';

const INITIAL_STATE = {
    packages: {
        byId: {},
        allIds: [],
    },
    loading: false,
    posting: false,
};

const  fetchPackages = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const  fetchPackagesSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('nfcId', action.payload.body);

    return {
        ...state,
        loading: false,
        packages: {
            byId,
            allIds,
        },
    };
};

const  fetchPackagesFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const createNewPackage = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const createNewPackageSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const createNewPackageFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const fetchPackageByid = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchPackageByidSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('nfcId', [action.payload.body]);

    return {
        ...state,
        loading: false,
        packages: {
            byId: byId,
            allIds: allIds,
        },
    };
};

const fetchPackageByidFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const deletePackageByid = (state, action) => {
    return {
        ...state,
    };
};

const deletePackageByidSuccess = (state, action) => {
    const deletedBy = (
        action.payload.request
        && action.payload.request.send
        && action.payload.request.send.nfcId
    ) ? action.payload.request.send.nfcId : null;

    if(!deletedBy){
        return {
            ...state,
        };
    }
    const items = _.pickBy(state.packages.byId, e => e.nfcId !== deletedBy);
    const allIds = state.packages.allIds.filter(e => e.nfcId !== deletedBy);

    return {
        ...state,
        packages: {
            allIds,
            byId: items,
        }
    };
};

const deletePackageByidFailed = (state, action) => {
    return {
        ...state,
    };
};

const editPackage = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const editPackageSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const editPackageFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const packages = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_PACKAGES]: fetchPackages,
    [ActionTypes.FETCH_PACKAGES_SUCCESS]: fetchPackagesSuccess,
    [ActionTypes.FETCH_PACKAGES_FAILED]: fetchPackagesFailed,
    [ActionTypes.CREATE_NEW_PACKAGE]: createNewPackage,
    [ActionTypes.CREATE_NEW_PACKAGE_SUCCESS]: createNewPackageSuccess,
    [ActionTypes.CREATE_NEW_PACKAGE_FAILED]: createNewPackageFailed,
    [ActionTypes.FETCH_PACKAGE_BY_ID]: fetchPackageByid,
    [ActionTypes.FETCH_PACKAGE_BY_ID_SUCCESS]: fetchPackageByidSuccess,
    [ActionTypes.FETCH_PACKAGE_BY_ID_FAILED]: fetchPackageByidFailed,
    [ActionTypes.DELETE_PACKAGE_BY_ID]: deletePackageByid,
    [ActionTypes.DELETE_PACKAGE_BY_ID_SUCCESS]: deletePackageByidSuccess,
    [ActionTypes.DELETE_PACKAGE_BY_ID_FAILED]: deletePackageByidFailed,

    [ActionTypes.EDIT_PACKAGE]: editPackage,
    [ActionTypes.EDIT_PACKAGE_SUCCESS]: editPackageSuccess,
    [ActionTypes.EDIT_PACKAGE_FAILED]: editPackageFailed,
});

export default packages;
