import React, {useCallback, useEffect} from 'react';
import * as actions from '../../actions/index';
import {useDispatch, useSelector} from 'react-redux';
import Packages from '../../components/packages/packages';
import packagesProjections from '../../projections/packages';

const PackagesContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.packages.loading);
    const packages = useSelector(packagesProjections.packages);

    const fetchPackagesAction = useCallback(
        (payload) => dispatch(actions.fetchPackages(payload)),
        [dispatch],
    );

    const deletePackageAction = useCallback(
        (payload) => dispatch(actions.deletePackageByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchPackagesAction();
    }, [fetchPackagesAction]);

    const handleDelete = (id) => {
        deletePackageAction({nfcId: id});
    };

    return (
        <Packages
            loading={loading}
            packages={packages}
            handleDelete={handleDelete}
        />
    );
};

export default PackagesContainer;