import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const login = createAction(ActionTypes.LOGIN);
export const loginSucceeded = createAction(ActionTypes.LOGIN_SUCCEEDED);
export const loginFailed = createAction(ActionTypes.LOGIN_FAILED);
export const updateToken = createAction(ActionTypes.UPDATE_TOKEN);
export const logout = createAction(ActionTypes.LOGOUT);

export const getApplicationUserDetails = createAction(ActionTypes.GET_APPLICATION_USER_DETAILS);
export const getApplicationUserDetailsSuccess = createAction(ActionTypes.GET_APPLICATION_USER_DETAILS_SUCCESS);
export const getApplicationUserDetailsFailed = createAction(ActionTypes.GET_APPLICATION_USER_DETAILS_FAILED);
