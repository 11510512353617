import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const fetchProducts = createAction(ActionTypes.FETCH_PRODUCTS);
export const fetchProductsSuccess = createAction(ActionTypes.FETCH_PRODUCTS_SUCCESS);
export const fetchProductsFailed = createAction(ActionTypes.FETCH_PRODUCTS_FAILED);

export const createNewProduct = createAction(ActionTypes.CREATE_NEW_PRODUCT);
export const createNewProductSuccess = createAction(ActionTypes.CREATE_NEW_PRODUCT_SUCCESS);
export const createNewProductFailed = createAction(ActionTypes.CREATE_NEW_PRODUCT_FAILED);

export const editProduct = createAction(ActionTypes.EDIT_PRODUCT);
export const editProductSuccess = createAction(ActionTypes.EDIT_PRODUCT_SUCCESS);
export const editProductFailed = createAction(ActionTypes.EDIT_PRODUCT_FAILED);

export const fetchProductByid = createAction(ActionTypes.FETCH_PRODUCT_BY_ID);
export const fetchProductByidSuccess = createAction(ActionTypes.FETCH_PRODUCT_BY_ID_SUCCESS);
export const fetchProductByidFailed = createAction(ActionTypes.FETCH_PRODUCT_BY_ID_FAILED);

export const deleteProductByid = createAction(ActionTypes.DELETE_PRODUCT_BY_ID);
export const deleteProductByidSuccess = createAction(ActionTypes.DELETE_PRODUCT_BY_ID_SUCCESS);
export const deleteProductByidFailed = createAction(ActionTypes.DELETE_PRODUCT_BY_ID_FAILED);

export const redirectAfterProductCreateEditSuccess = createAction(ActionTypes.REDIRECT_AFTER_CREATE_EDIT_SUCCESS);
