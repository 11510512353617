import React, {useCallback, useEffect} from 'react';
import {Form, Formik} from 'formik';
import FormikFieldWrapper from '../general/formikFieldWrapper/formikFieldWrapper';
import {Button, Heading, Loader} from 'arag-ui-lib';
import {useDispatch, useSelector} from 'react-redux';
import productsProjections from '../../projections/products';
import * as actions from '../../actions';

const PackagesForm = (props) => {
    const {handleSubmit, title, submitLabel, formInitialValues = {}, disabledFieldKeys = [], loading} = props;
    const dispatch = useDispatch();
    const productsDropdownOptions = useSelector(productsProjections.productsDropdownOptions);

    const fetchProductsAction = useCallback(
        (payload) => dispatch(actions.fetchProducts(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchProductsAction();
    }, [fetchProductsAction]);

    return (
        <div>
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
              <div>
                  <Heading variant="h2" title={title} />
                  <Form
                      className="form-horizontal"
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                  >
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'NfcId'}
                          variant={'text'}
                          inputProps={{
                              required: true,
                              name: 'nfcId',
                              disabled: disabledFieldKeys ?
                                  disabledFieldKeys.includes('nfcId') : false,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Code'}
                          variant={'text'}
                          inputProps={{
                              required: true,
                              name: 'code',
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Pack'}
                          variant={'text'}
                          inputProps={{
                              required: true,
                              name: 'pack',
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Description'}
                          variant={'textarea'}
                          inputProps={{
                              required: true,
                              name: 'description',
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Product'}
                          variant={'select'}
                          inputProps={{
                              required: true,
                              name: 'productId',
                              options: productsDropdownOptions,
                          }}
                      />
                      <div className={'form-group'}>
                          <div className={'col-sm-7 col-sm-offset-3'}>
                              <Button type="submit" variant="primary" label={submitLabel} arrowDirection="right" />
                              {loading && (
                                  <Loader variant={'inline'}/>
                              )}
                          </div>
                      </div>
                  </Form>
              </div>
            </Formik>
        </div>
    );
};

export default PackagesForm;
