import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    message: 'HellO MOTO',
    accessToken:null,
    details: null,
    loggingIn: false,
};

const login = (state, action) => {
    return {
        ...state,
        loggingIn: true,
    };
};

const loginSucceeded = (state, action) => {
    return {
        ...state,
        accessToken:action.payload.body.access_token,
        loggingIn: false,
    };
};

const loginFailed = (state, action) => {
    return {
        ...state,
        loggingIn: false,
    };
};

const updateToken = (state, action) => {
    return {
        ...state,
        accessToken:action.payload.body.accessToken,
    };
};

const getApplicationUserDetails = (state, action) => {
    return {
        ...state,
    };
};

const getApplicationUserDetailsSuccess = (state, action) => {
    return {
        ...state,
        details: action.payload.body,
    };
};

const getApplicationUserDetailsFailed = (state, action) => {
    return {
        ...state,
    };
};


const applicationUser = createReducer(INITIAL_STATE, {
    [ActionTypes.LOGIN]: login,
    [ActionTypes.LOGIN_SUCCEEDED]: loginSucceeded,
    [ActionTypes.LOGIN_FAILED]: loginFailed,
    [ActionTypes.UPDATE_TOKEN]: updateToken,
    [ActionTypes.GET_APPLICATION_USER_DETAILS]: getApplicationUserDetails,
    [ActionTypes.GET_APPLICATION_USER_DETAILS_SUCCESS]: getApplicationUserDetailsSuccess,
    [ActionTypes.GET_APPLICATION_USER_DETAILS_FAILED]: getApplicationUserDetailsFailed,
});

export default applicationUser;
