import React, {useCallback, useState} from 'react';
import {
    NavBar,
    NavContainer,
    NavHeader,
    NavContent,
    NavUser,
} from 'arag-ui-lib';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import applicationUserProjections from '../../projections/applicationUser';

const LinkWrapper = ({children}) => {
    return (
        <div style={{cursor: 'pointer'}}>
            {children}
        </div>
    );
};

const Header = (props) => {
    const {} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const applicationUserDisplayName = useSelector(applicationUserProjections.applicationUserDisplayName);
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => { setExpanded(!expanded)};

    const logoutAction = useCallback(
        () => {
            dispatch(actions.logout());
            history.push('/');
        },
        [dispatch],
    );

    return (
        <NavBar>
            <NavContainer>
                <NavContent>
                    <NavHeader onClick={() => history.push('/distributors')} onBarsClick={toggleExpanded} >
                        <LinkWrapper>
                            Distributors
                        </LinkWrapper>
                    </NavHeader>
                    <NavHeader onClick={() => history.push('/producers')} onBarsClick={toggleExpanded} >
                        <LinkWrapper>
                            Producers
                        </LinkWrapper>
                    </NavHeader>
                    <NavHeader onClick={() => history.push('/products')} onBarsClick={toggleExpanded} >
                        <LinkWrapper>
                            Products
                        </LinkWrapper>
                    </NavHeader>
                    <NavHeader onClick={() => history.push('/packages')} onBarsClick={toggleExpanded} >
                        <LinkWrapper>
                            Packages
                        </LinkWrapper>
                    </NavHeader>
                    <NavUser dropdownLabel={applicationUserDisplayName} options={[{label: 'Sign out',callback: logoutAction}]} />
                </NavContent>
            </NavContainer>
        </NavBar>
    );
};

export default Header;