import React, {useCallback} from 'react';
import FormikFieldWrapper from '../general/formikFieldWrapper/formikFieldWrapper';
import {Form, Formik} from 'formik';
import {Button, Heading, Loader} from 'arag-ui-lib';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';


const LoginForm = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const loggingIn = useSelector(state => state.applicationUser.loggingIn);

    const loginAction = useCallback(
        (payload) => dispatch(actions.login(payload)),
        [dispatch],
    );

    const handleSubmit = (values) => {
        loginAction(values);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-4 col-md-offset-4">
                    <div className={'text-center'}>
                        <Heading variant={'h2'} title={'Sign in'} />
                    </div>
                    <div className="account-wall">
                        <Formik initialValues={{}} onSubmit={handleSubmit}>
                            <Form
                                className="form-horizontal"
                                autoComplete="off"
                                autoCorrect="off"
                                spellCheck="off"
                            >
                                <FormikFieldWrapper
                                    labelSize={'col-sm-3'}
                                    inputSize={'col-sm-9'}
                                    label={'Username'}
                                    variant={'text'}
                                    inputProps={{
                                        name: 'username',
                                    }}
                                />
                                <FormikFieldWrapper
                                    labelSize={'col-sm-3'}
                                    inputSize={'col-sm-9'}
                                    label={'Password'}
                                    variant={'text'}
                                    inputProps={{
                                        name: 'password',
                                        isPassword: true,
                                    }}
                                />
                                <div className={'form-group'}>
                                    <div className={'col-sm-9 col-sm-offset-3'}>
                                        <Button type="submit" variant="primary" label="Sign in" arrowDirection="right" />
                                        {loggingIn && (
                                            <Loader variant="inline"/>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;