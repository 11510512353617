import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import * as actions from '../actions';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import HEADER_FUNCTIONS from '../utils/functions/headers';
import {history} from '../store/store';

const REQUEST_PACKAGES = 'requestPackages';
const REQUEST_CREATE_PACKAGE = 'requestCreatePackage';
const REQUEST_PATCH_PACKAGE = 'requestPatchPackage';
const REQUEST_FETCH_PACKAGE = 'requestFetchPackage';
const REQUEST_DELETE_PACKAGE = 'requestDeletePackage';

export function redirectAfterPackageCreateSuccess(sources) {
    const action$ = sources.ACTION
        .filter(action => (
            action.type === ActionTypes.CREATE_NEW_PACKAGE_SUCCESS
            || action.type === ActionTypes.EDIT_PACKAGE_SUCCESS
        ))
        .map(action => {
            history.push('/packages');
            return actions.redirectAfterPackageCreateEditSuccess();
        });

    return {
        ACTION: action$,
    };
}

export function requestFetchPackage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PACKAGE_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Package/ReadPackage/${action.payload}`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_FETCH_PACKAGE,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_PACKAGE)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchPackageByidSuccess(response)
                : actions.fetchPackageByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestDeletePackage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.DELETE_PACKAGE_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Package/DeletePackage`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_DELETE_PACKAGE,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_DELETE_PACKAGE)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.deletePackageByidSuccess(response)
                : actions.deletePackageByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestPatchPackage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.EDIT_PACKAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Package/UpdatePackage`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PATCH_PACKAGE,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PATCH_PACKAGE)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.editPackageSuccess(response)
                : actions.editPackageFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestCreatePackage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.CREATE_NEW_PACKAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Package/CreatePackage`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_CREATE_PACKAGE,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_PACKAGE)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 201
                ? actions.createNewPackageSuccess(response)
                : actions.createNewPackageFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestPackages(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PACKAGES)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Package/ReadAllPackages`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PACKAGES,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PACKAGES)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchPackagesSuccess(response)
                : actions.fetchPackagesFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
