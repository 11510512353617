import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import PackagesForm from '../../components/packagesForm/packagesForm';

const CreatePackageContainer = (props) => {
    const {} = props;
    const posting = useSelector(state => state.packages.posting);
    const dispatch = useDispatch();

    const createPackageAction = useCallback(
        (payload) => dispatch(actions.createNewPackage(payload)),
        [dispatch],
    );


    const handleSubmit = values => {
        createPackageAction(values);
    };

    return (
        <div>
            <PackagesForm
                title={'Create New package'}
                submitLabel={'Create'}
                handleSubmit={handleSubmit}
                loading={posting}
            />
        </div>
    );
};

export default CreatePackageContainer;