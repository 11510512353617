import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import * as actions from '../actions';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import HEADER_FUNCTIONS from '../utils/functions/headers';
import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import {history} from '../store/store';

const REQUEST_LOGIN = 'requestLogin';
const REQUEST_APPLICATION_USER_DETAILS = 'requestApplicationUserDetails';

export function expiredToken(sources) {
    let httpResponse$ = sources.HTTP
        .select()
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter((response)=> response.status === 401)
        .map(response => actions.logout());

    return {
        ACTION: httpResponse$,
    };
}


export function logoutAfterForbidden(sources) {
    const action$ = sources.ACTION
        .filter(action => (
            action.type === ActionTypes.CREATE_NEW_PRODUCT_SUCCESS
            || action.type === ActionTypes.EDIT_PRODUCT_SUCCESS
        ))
        .map(action => {
            history.push('/products');
            return actions.redirectAfterProductCreateEditSuccess();
        });

    return {
        ACTION: action$,
    };
}

export function requestApplicationUserDetails(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.GET_APPLICATION_USER_DETAILS)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: GENERAL_FUNCTIONS.getBaseURL() + '/Users/me',
            category: REQUEST_APPLICATION_USER_DETAILS,
            headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
            method: 'GET',
            send: action.payload,
        }));

    let httpResponse$ = sources.HTTP.select(REQUEST_APPLICATION_USER_DETAILS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (response.status === 200 ?
            actions.getApplicationUserDetailsSuccess(response)
            : actions.getApplicationUserDetailsFailed(response)),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestAppUserDetailsAfterLoginSuccess(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === ActionTypes.LOGIN_SUCCEEDED)
        .map(response => actions.getApplicationUserDetails());

    return {
        ACTION: action$,
    };
}


export function requestLogin(sources) {
    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.LOGIN).map(action => ({
        url: GENERAL_FUNCTIONS.getBaseURL() + '/Users/Login',
        category: REQUEST_LOGIN,
        headers: HEADER_FUNCTIONS.defaultHeaders(),
        method: 'POST',
        send: action.payload,
    }));

    let httpResponse$ = sources.HTTP.select(REQUEST_LOGIN)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (response.status === 201 ? actions.loginSucceeded(response) : actions.loginFailed(response)));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
