const config = {};

config.defaultListSize = 20;

config.listSizeOptions = [
    { key: 5, label: 5 },
    { key: 10, label: 10 },
    { key: 20, label: 20 },
    { key: 50, label: 50 },
];

config.MAX_ALLOWED_SIZE_IN_BYTES = 30000000;


config.ACCEPTED_FILE_EXTENSIONS = [
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.jpg',
    'image/jpeg',
    'image/jpg',
    '.msg',
    '.bmp',
    '.png',
    '.txt',
    '.eml',
    '.dot',
    '.zip',
    '.rar',
];

config.ELMAH_IO_API_KEY =  process.env.REACT_APP_ELMAH_IO_API_KEY;

config.ELMAH_IO_LOG_ID =  process.env.REACT_APP_ELMAH_IO_LOG_ID;

config.ENV_NAME =  process.env.REACT_APP_ENV_NAME;


config.StatusMessage = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',

};

config.currencyPosition = {
    before: 'before',
    after: 'after',
};

export default config;