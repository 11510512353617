import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const fetchDistributors = createAction(ActionTypes.FETCH_DISTRIBUTORS);
export const fetchDistributorsSuccess = createAction(ActionTypes.FETCH_DISTRIBUTORS_SUCCESS);
export const fetchDistributorsFailed = createAction(ActionTypes.FETCH_DISTRIBUTORS_FAILED);

export const createNewDistributor = createAction(ActionTypes.CREATE_NEW_DISTRIBUTOR);
export const createNewDistributorSuccess = createAction(ActionTypes.CREATE_NEW_DISTRIBUTOR_SUCCESS);
export const createNewDistributorFailed = createAction(ActionTypes.CREATE_NEW_DISTRIBUTOR_FAILED);

export const editDistributor = createAction(ActionTypes.EDIT_DISTRIBUTOR);
export const editDistributorSuccess = createAction(ActionTypes.EDIT_DISTRIBUTOR_SUCCESS);
export const editDistributorFailed = createAction(ActionTypes.EDIT_DISTRIBUTOR_FAILED);

export const fetchDistributorByid = createAction(ActionTypes.FETCH_DISTRIBUTOR_BY_ID);
export const fetchDistributorByidSuccess = createAction(ActionTypes.FETCH_DISTRIBUTOR_BY_ID_SUCCESS);
export const fetchDistributorByidFailed = createAction(ActionTypes.FETCH_DISTRIBUTOR_BY_ID_FAILED);

export const deleteDistributorByid = createAction(ActionTypes.DELETE_DISTRIBUTOR_BY_ID);
export const deleteDistributorByidSuccess = createAction(ActionTypes.DELETE_DISTRIBUTOR_BY_ID_SUCCESS);
export const deleteDistributorByidFailed = createAction(ActionTypes.DELETE_DISTRIBUTOR_BY_ID_FAILED);

export const redirectAfterDistributorCreateEditSuccess = createAction(ActionTypes.REDIRECT_AFTER_CREATE_EDIT_SUCCESS);
