import {createSelector} from '@reduxjs/toolkit';
import React from 'react';
import config from '../utils/constants/config';

const messageProjections = {};

messageProjections.applicationMessageList = notificationCallback => createSelector(
    state => state.message.byId,
    messagesById => {
        return Object
            .entries(messagesById)
            .map(([key, value]) => {
                const notificationLabel = Array.isArray(value.message)
                    ? (
                        <ul>
                            {value.message.map((m, index) =>
                                <li key={index}>
                                    {m}
                                </li>,
                            )}
                        </ul>
                    )
                    : value.message;

                if(notificationCallback){
                    return {
                        key: value.uuid,
                        label: notificationLabel,
                        callback: notificationCallback,
                        variant: value.type,
                    };
                }

                return  {
                    key: value.uuid,
                    label: notificationLabel,
                    variant: value.type,
                };
            });
    },
);

messageProjections.totalErrorMessages = createSelector(
    state => state.message.byId,
    messagesById => {
        return Object
            .entries(messagesById)
            .filter(([key, value]) => value.type === config.StatusMessage.ERROR)
            .length;
    },
);

export default messageProjections;