import {createSelector} from '@reduxjs/toolkit';

const distributorsProjections = {};

distributorsProjections.distributors = createSelector(
    state => state.distributors.distributors.byId,
    distributorsById => {
        return Object
            .values(distributorsById)
            .map(distributor => {
                return {
                    id: distributor.id,
                    legalName: distributor.legalName,
                    brandName: distributor.brandName,
                };
            });
    },
);

distributorsProjections.distributorsDropdownOptions = createSelector(
    state => state.distributors.distributors.byId,
    distributorsById => {
        return [
            {key: '', value: ''},
            ...Object
                .values(distributorsById)
                .map(distributor => {
                    return {
                        key: distributor.id,
                        label: distributor.legalName,
                    };
                }),
        ];
    },
);

distributorsProjections.distributorFormikInitialValues = distributorId => createSelector(
    state => state.distributors.distributors.byId,
    distributorsBydId => {
        const distributor = Object
            .values(distributorsBydId)
            .find(d => d.legalName === distributorId);


        if(!distributor) {
            return  {};
        }

        return {
            legalName: distributor.legalName,
            brandName: distributor.brandName,
        };
    },
);


export default distributorsProjections;