import React from 'react';
import { history } from '../store/store';
import { Router } from 'react-router-dom';
import LayoutContainer from '../containers/layout/layoutContainer';
import {useSelector} from 'react-redux';
import applicationUserProjections from '../projections/applicationUser';
import LoginForm from '../components/loginForm/loginForm';
import ApplicationMessages from '../components/applicationMessages/applicationMessages';

const MainRouter = props => {
    const isLoggedIn = useSelector(applicationUserProjections.isLoggedIn);

    return (
        <Router history={history}>
            {
                isLoggedIn
                    ? (
                        <LayoutContainer/>
                    )
                    : (
                        <div>
                            <ApplicationMessages/>
                            <LoginForm/>
                        </div>
                    )
            }
        </Router>

    );

};

export default MainRouter;