import applicationUser from './applicationUser';
import message from './message';
import distributors from './distributors';
import producers from './producers';
import products from './products';
import packages from './packages';
import {combineReducers} from 'redux';
import * as ActionsTypes from '../actions/types';

const reducers = combineReducers({
    packages,
    products,
    producers,
    distributors,
    message,
    applicationUser,
});

const resettableRootReducer = (state, action) => {
    if (action.type === ActionsTypes.LOGOUT) {
        return reducers(undefined, action);
    }
    return reducers(state, action);
};

export default resettableRootReducer;
