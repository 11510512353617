import {createSelector} from '@reduxjs/toolkit';

const productsProjections = {};

productsProjections.products = createSelector(
    state => state.products.products.byId,
    productsById => {
        return Object
            .values(productsById)
            .map(product => {
                return {
                    id: product.id,
                    brandName: product.brandName,
                    medicalName: product.medicalName,
                };
            });
    },
);

productsProjections.productsDropdownOptions = createSelector(
    state => state.products.products.byId,
    productsById => {
        return [
            {key: '', label: ''},
            ...Object
                .values(productsById)
                .map(product => {
                    return {
                        key: product.id,
                        label: product.brandName,
                    };
                }),
        ];
    },
);

productsProjections.productsFormikInitialValues = productId => createSelector(
    state => state.products.products.byId,
    productsById => {
        const product = Object
            .values(productsById)
            .find(e => e.medicalName === productId);

        if(!product) {
            return  {};
        }

        return {
            medicalName: product.medicalName,
            brandName: product.brandName,
            distributorId: product.distributorId,
            producerId: product.producerId,
            pxp: product.pxp ? [{name: '', encodedFile: product.pxp}] : null,
        };
    },
);


export default productsProjections;