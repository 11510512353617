const rules = {};

rules.minAge = {
    code: 'min-age',
};

rules.maxAge = {
    code: 'max-age',
};

rules.minValue = {
    code: 'min-value',
};

rules.maxValue = {
    code: 'max-value',
};

rules.minLength = {
    code: 'min-length',
};

rules.maxLength = {
    code: 'max-length',
};

rules.futureDate = {
    code: 'future-date',
};

rules.pastDate = {
    code: 'past-date',
};

rules.todayFutureDate = {
    code: 'today-future-date',
};

rules.todayPastDate = {
    code: 'today-past-date',
};

export default rules;