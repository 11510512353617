import React from 'react';
import Content from '../content/content';
import Header from '../header/header';
import styles from './layout.module.scss';
import ApplicationMessages from '../applicationMessages/applicationMessages';

const Layout = props => {
    return (
        <React.Fragment>
            <ApplicationMessages/>
            <Header />
            <div className={`container ${styles['body-content']}`} style={{marginTop: '40px'}}>
                <Content/>
            </div>
        </React.Fragment>
    );
};

export default Layout;
