import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import {useParams} from 'react-router-dom';
import productsProjections from '../../projections/products';
import ProductsForm from '../../components/productsForm/productsForm';

const EditProductContainer = (props) => {
    const {} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const formInitialValues = useSelector(productsProjections.productsFormikInitialValues(id));
    const posting = useSelector(state => state.products.posting);

    const editProductAction = useCallback(
        (payload) => dispatch(actions.editProduct(payload)),
        [dispatch],
    );

    const createErrorMessageAction = useCallback(
        (payload) => dispatch(actions.addErrorMessage(payload)),
        [dispatch],
    );

    const fetchProductAction = useCallback(
        (payload) => dispatch(actions.fetchProductByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchProductAction(id);
    }, [id]);

    const handleSubmit = values => {
        editProductAction({
            ...values,
            pxp: (values.pxp && values.pxp.length === 1) ?  values.pxp[0].encodedFile : null,
        });
    };

    return (
        <div>
            <ProductsForm
                disabledFieldKeys={['medicalName']}
                title={'Edit Product'}
                submitLabel={'Edit'}
                handleSubmit={handleSubmit}
                formInitialValues={formInitialValues}
                loading={posting}
            />
        </div>
    );
};

export default EditProductContainer;