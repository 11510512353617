import React from 'react';
import {Form, Formik} from 'formik';
import FormikFieldWrapper from '../general/formikFieldWrapper/formikFieldWrapper';
import {Button, Heading, Loader} from 'arag-ui-lib';

const DistributorsForm = (props) => {
    const {handleSubmit, title, submitLabel, formInitialValues = {}, disabledFieldKeys = [], loading} = props;

    return (
        <div>
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
              <div>
                  <Heading variant="h2" title={title} />
                  <Form
                      className="form-horizontal"
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                  >
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Legal Name'}
                          variant={'text'}
                          inputProps={{
                              required: true,
                              name: 'legalName',
                              disabled: disabledFieldKeys ?
                                  disabledFieldKeys.includes('legalName') : false,
                          }}
                      />
                      <FormikFieldWrapper
                          labelSize={'col-sm-3'}
                          inputSize={'col-sm-7'}
                          label={'Brand Name'}
                          variant={'text'}
                          inputProps={{
                              required: true,
                              name: 'brandName',
                          }}
                      />
                      <div className={'form-group'}>
                          <div className={'col-sm-7 col-sm-offset-3'}>
                              <Button type="submit" variant="primary" label={submitLabel} arrowDirection="right" />
                              {loading && (
                                  <Loader variant={'inline'} />
                              )}
                          </div>
                      </div>
                  </Form>
              </div>
            </Formik>
        </div>
    );
};

export default DistributorsForm;