import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import _ from 'lodash';

const INITIAL_STATE = {
    products: {
        byId: {},
        allIds: [],
    },
    loading: false,
    posting: false,
};

const  fetchProducts = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const  fetchProductsSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('id', action.payload.body);

    return {
        ...state,
        loading: false,
        products: {
            byId,
            allIds,
        },
    };
};

const  fetchProductsFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const createNewProduct = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const createNewProductSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const createNewProductFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const fetchProductByid = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchProductByidSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('id', [action.payload.body]);

    return {
        ...state,
        loading: false,
        products: {
            byId: byId,
            allIds: allIds,
        },
    };
};

const fetchProductByidFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const deleteProductByid = (state, action) => {
    return {
        ...state,
    };
};

const deleteProductByidSuccess = (state, action) => {
    const deletedBy = (
        action.payload.request
        && action.payload.request.send
        && action.payload.request.send.medicalName
    ) ? action.payload.request.send.medicalName : null;

    if(!deletedBy){
        return {
            ...state,
        };
    }
    const items = _.pickBy(state.products.byId, e => e.medicalName !== deletedBy);
    const allIds = state.products.allIds.filter(e => e.medicalName !== deletedBy);

    return {
        ...state,
        products: {
            byId: items,
            allIds,
        },
    };
};

const deleteProductByidFailed = (state, action) => {
    return {
        ...state,
    };
};

const editProduct = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const editProductSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const editProductFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};


const products = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_PRODUCTS]: fetchProducts,
    [ActionTypes.FETCH_PRODUCTS_SUCCESS]: fetchProductsSuccess,
    [ActionTypes.FETCH_PRODUCTS_FAILED]: fetchProductsFailed,
    [ActionTypes.CREATE_NEW_PRODUCT]: createNewProduct,
    [ActionTypes.CREATE_NEW_PRODUCT_SUCCESS]: createNewProductSuccess,
    [ActionTypes.CREATE_NEW_PRODUCT_FAILED]: createNewProductFailed,
    [ActionTypes.FETCH_PRODUCT_BY_ID]: fetchProductByid,
    [ActionTypes.FETCH_PRODUCT_BY_ID_SUCCESS]: fetchProductByidSuccess,
    [ActionTypes.FETCH_PRODUCT_BY_ID_FAILED]: fetchProductByidFailed,
    [ActionTypes.DELETE_PRODUCT_BY_ID]: deleteProductByid,
    [ActionTypes.DELETE_PRODUCT_BY_ID_SUCCESS]: deleteProductByidSuccess,
    [ActionTypes.DELETE_PRODUCT_BY_ID_FAILED]: deleteProductByidFailed,

    [ActionTypes.EDIT_PRODUCT]: editProduct,
    [ActionTypes.EDIT_PRODUCT_SUCCESS]: editProductSuccess,
    [ActionTypes.EDIT_PRODUCT_FAILED]: editProductFailed,
});

export default products;
