const HEADER_FUNCTIONS = {};

HEADER_FUNCTIONS.headersWithTokenJson = (token) => {
    return ({
        accept: 'application/json',
        'CONTENT-TYPE': 'application/json',
        'Authorization': `Bearer ${token}`,
    });
};

HEADER_FUNCTIONS.defaultHeaders = () => {
    return ({
        accept: 'application/json',
        'CONTENT-TYPE': 'application/json',
    });
};


export default HEADER_FUNCTIONS;