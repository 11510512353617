import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const fetchPackages = createAction(ActionTypes.FETCH_PACKAGES);
export const fetchPackagesSuccess = createAction(ActionTypes.FETCH_PACKAGES_SUCCESS);
export const fetchPackagesFailed = createAction(ActionTypes.FETCH_PACKAGES_FAILED);

export const createNewPackage = createAction(ActionTypes.CREATE_NEW_PACKAGE);
export const createNewPackageSuccess = createAction(ActionTypes.CREATE_NEW_PACKAGE_SUCCESS);
export const createNewPackageFailed = createAction(ActionTypes.CREATE_NEW_PACKAGE_FAILED);

export const editPackage = createAction(ActionTypes.EDIT_PACKAGE);
export const editPackageSuccess = createAction(ActionTypes.EDIT_PACKAGE_SUCCESS);
export const editPackageFailed = createAction(ActionTypes.EDIT_PACKAGE_FAILED);

export const fetchPackageByid = createAction(ActionTypes.FETCH_PACKAGE_BY_ID);
export const fetchPackageByidSuccess = createAction(ActionTypes.FETCH_PACKAGE_BY_ID_SUCCESS);
export const fetchPackageByidFailed = createAction(ActionTypes.FETCH_PACKAGE_BY_ID_FAILED);

export const deletePackageByid = createAction(ActionTypes.DELETE_PACKAGE_BY_ID);
export const deletePackageByidSuccess = createAction(ActionTypes.DELETE_PACKAGE_BY_ID_SUCCESS);
export const deletePackageByidFailed = createAction(ActionTypes.DELETE_PACKAGE_BY_ID_FAILED);

export const redirectAfterPackageCreateEditSuccess = createAction(ActionTypes.REDIRECT_AFTER_CREATE_EDIT_SUCCESS);
