import {createSelector} from '@reduxjs/toolkit';

const producerProjections = {};

producerProjections.producers = createSelector(
    state => state.producers.producers.byId,
    producersById => {
        return Object
            .values(producersById)
            .map(producer => {
                return {
                    legalName: producer.legalName,
                    brandName: producer.brandName,
                };
            });
    },
);

producerProjections.producersDropdownOptions = createSelector(
    state => state.producers.producers.byId,
    producersById => {
        return [
            {key: '', label: ''},
            ...Object
                .values(producersById)
                .map(producer => {
                    return {
                        key: producer.id,
                        label: producer.legalName,
                    };
                }),
        ];
    },
);


producerProjections.producerFormikInitialValues = producerId => createSelector(
    state => state.producers.producers.byId[producerId],
    producer => {
        if(!producer) {
            return  {};
        }

        return {
            legalName: producer.legalName,
            brandName: producer.brandName,
        };
    },
);


export default producerProjections;