import React from 'react';
import { FormGroup } from 'arag-ui-lib';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormikFieldWrapper = props => {
    const {
        inputProps,
        labelSize,
        inputSize,
        label,
        variant,
        helpText,
        validator,
        getInputProps,
        groupShape,
        inline,
    } = props;

    return (
        <Field
            name={inputProps.name}
            validate={validator}
        >
            {({ field, form: { touched, errors, setFieldValue, values, setValues, resetForm }, meta }) => {
                let finalInputProps = {};
                if(getInputProps){
                    finalInputProps = getInputProps({field, values, setFieldValue, setValues, resetForm});
                } else if (inputProps){
                    finalInputProps = inputProps;
                }


                return (
                    <FormGroup
                        inline={inline}
                        helpText={helpText}
                        errorMessage={meta.touched && meta.error ? meta.error : null}
                        labelSize={labelSize}
                        inputSize={inputSize}
                        label={label}
                        variant={variant}
                        groupShape={groupShape}
                        inputProps={{
                            ...field,
                            value: field.value || '',
                            ...finalInputProps,
                        }}
                    />
                );
            }}
        </Field>
    );
};

FormikFieldWrapper.propTypes = {
    inputProps: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    label: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([
        'searchableList',
        'textarea',
        'date',
        'checkbox',
        'text',
        'select',
        'multiSelect',
        'plain',
        'creatableSelect',
        'dragDrop',
        'asyncSearchableList',
        'currency',
        'checkbox-inline',
    ]).isRequired,
    getInputProps: PropTypes.func,
};

export default FormikFieldWrapper;
