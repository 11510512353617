import React, {useCallback, useEffect} from 'react';
import * as actions from '../../actions/index';
import {useDispatch, useSelector} from 'react-redux';
import Distributors from '../../components/distributors/distributors';
import distributorsProjections from '../../projections/distributors';

const DistributorsContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.distributors.loading);
    const distributors = useSelector(distributorsProjections.distributors);

    const fetchDistributorsAction = useCallback(
        (payload) => dispatch(actions.fetchDistributors(payload)),
        [dispatch],
    );

    const deleteDistributorAction = useCallback(
        (payload) => dispatch(actions.deleteDistributorByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchDistributorsAction();
    }, [fetchDistributorsAction]);

    const handleDelete = (id) => {
        deleteDistributorAction({legalName: id});
    };

    return (
        <Distributors
            loading={loading}
            distributors={distributors}
            handleDelete={handleDelete}
        />
    );
};

export default DistributorsContainer;