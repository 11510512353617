import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import ProductsForm from '../../components/productsForm/productsForm';

const CreateProductContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const posting = useSelector(state => state.products.posting);

    const createProductAction = useCallback(
        (payload) => dispatch(actions.createNewProduct(payload)),
        [dispatch],
    );

    const createErrorMessageAction = useCallback(
        (payload) => dispatch(actions.addErrorMessage(payload)),
        [dispatch],
    );

    const handleSubmit = values => {

        createProductAction({
            ...values,
            pxp: (values.pxp && values.pxp.length === 1) ?  values.pxp[0].encodedFile : null,
        });
    };

    return (
        <div>
            <ProductsForm
                title={'Create New product'}
                submitLabel={'Create'}
                handleSubmit={handleSubmit}
                loading={posting}
            />
        </div>
    );
};

export default CreateProductContainer;