import React, {useCallback, useEffect} from 'react';
import * as actions from '../../actions/index';
import {useDispatch, useSelector} from 'react-redux';
import Producers from '../../components/producers/producers';
import producerProjections from '../../projections/producers';

const ProducersContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.producers.loading);
    const producers = useSelector(producerProjections.producers);

    const fetchProducersAction = useCallback(
        (payload) => dispatch(actions.fetchProducers(payload)),
        [dispatch],
    );

    const deleteProducerAction = useCallback(
        (payload) => dispatch(actions.deleteProducerByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchProducersAction();
    }, [fetchProducersAction]);

    const handleDelete = (id) => {
        deleteProducerAction({legalName: id});
    };

    return (
        <Producers
            loading={loading}
            producers={producers}
            handleDelete={handleDelete}
        />
    );
};

export default ProducersContainer;