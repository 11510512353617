import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import _ from 'lodash';

const INITIAL_STATE = {
    distributors: {
        byId: {},
        allIds: [],
    },
    loading: false,
    posting: false,
};

const  fetchDistributors = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const  fetchDistributorsSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('id', action.payload.body);

    return {
        ...state,
        loading: false,
        distributors: {
            byId,
            allIds,
        },
    };
};

const  fetchDistributorsFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const createNewDistributor = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const createNewDistributorSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const createNewDistributorFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};


const fetchDistributorByid = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchDistributorByidSuccess = (state, action) => {
    const {byId, allIds} = GENERAL_FUNCTIONS
        .normalizeById('id', [action.payload.body]);

    return {
        ...state,
        loading: false,
        distributors: {
            byId: byId,
            allIds: allIds,
        },
    };
};

const fetchDistributorByidFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};

const deleteDistributorByid = (state, action) => {
    return {
        ...state,
    };
};

const deleteDistributorByidSuccess = (state, action) => {
    const deletedBy = (
        action.payload.request
        && action.payload.request.send
        && action.payload.request.send.legalName
    ) ? action.payload.request.send.legalName : null;

    if(!deletedBy){
        return {
            ...state,
        };
    }
    const items = _.pickBy(state.distributors.byId, e => e.legalName !== deletedBy);
    const allIds = state.distributors.allIds.filter(e => e.legalName !== deletedBy);

    return {
        ...state,
        distributors: {
            allIds,
            byId: items,
        },
    };
};

const deleteDistributorByidFailed = (state, action) => {
    return {
        ...state,
    };
};

const editDistributor = (state, action) => {
    return {
        ...state,
        posting: true,
    };
};

const editDistributorSuccess = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};

const editDistributorFailed = (state, action) => {
    return {
        ...state,
        posting: false,
    };
};


const distributors = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_DISTRIBUTORS]: fetchDistributors,
    [ActionTypes.FETCH_DISTRIBUTORS_SUCCESS]: fetchDistributorsSuccess,
    [ActionTypes.FETCH_DISTRIBUTORS_FAILED]: fetchDistributorsFailed,
    [ActionTypes.CREATE_NEW_DISTRIBUTOR]: createNewDistributor,
    [ActionTypes.CREATE_NEW_DISTRIBUTOR_SUCCESS]: createNewDistributorSuccess,
    [ActionTypes.CREATE_NEW_DISTRIBUTOR_FAILED]: createNewDistributorFailed,
    [ActionTypes.FETCH_DISTRIBUTOR_BY_ID]: fetchDistributorByid,
    [ActionTypes.FETCH_DISTRIBUTOR_BY_ID_SUCCESS]: fetchDistributorByidSuccess,
    [ActionTypes.FETCH_DISTRIBUTOR_BY_ID_FAILED]: fetchDistributorByidFailed,
    [ActionTypes.DELETE_DISTRIBUTOR_BY_ID]: deleteDistributorByid,
    [ActionTypes.DELETE_DISTRIBUTOR_BY_ID_SUCCESS]: deleteDistributorByidSuccess,
    [ActionTypes.DELETE_DISTRIBUTOR_BY_ID_FAILED]: deleteDistributorByidFailed,
    [ActionTypes.EDIT_DISTRIBUTOR]: editDistributor,
    [ActionTypes.EDIT_DISTRIBUTOR_SUCCESS]: editDistributorSuccess,
    [ActionTypes.EDIT_DISTRIBUTOR_FAILED]: editDistributorFailed,
});

export default distributors;
