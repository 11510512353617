import React, {useCallback, useEffect} from 'react';
import * as actions from '../../actions/index';
import {useDispatch, useSelector} from 'react-redux';
import Products from '../../components/products/products';
import productsProjections from '../../projections/products';

const ProductsContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.products.loading);
    const products = useSelector(productsProjections.products);

    const fetchProductsAction = useCallback(
        (payload) => dispatch(actions.fetchProducts(payload)),
        [dispatch],
    );

    const deleteProductAction = useCallback(
        (payload) => dispatch(actions.deleteProductByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchProductsAction();
    }, [fetchProductsAction]);

    const handleDelete = (id) => {
        deleteProductAction({medicalName: id});
    };

    return (
        <Products
            loading={loading}
            products={products}
            handleDelete={handleDelete}
        />
    );
};

export default ProductsContainer;