import React, {useCallback, useEffect} from 'react';
import DistributorsForm from '../../components/distributorsForm/distributorsForm';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import {useParams} from 'react-router-dom';
import distributorsProjections from '../../projections/distributors';

const EditDistributorContainer = (props) => {
    const {} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const formInitialValues = useSelector(distributorsProjections.distributorFormikInitialValues(id));
    const posting = useSelector(state => state.distributors.posting);

    const editDistributorAction = useCallback(
        (payload) => dispatch(actions.editDistributor(payload)),
        [dispatch],
    );

    const fetchDistributorAction = useCallback(
        (payload) => dispatch(actions.fetchDistributorByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchDistributorAction(id);
    }, [id]);

    const handleSubmit = values => {
        editDistributorAction(values);
    };

    return (
        <div>
            <DistributorsForm
                disabledFieldKeys={['legalName']}
                title={'Edit Distributor'}
                submitLabel={'Edit'}
                handleSubmit={handleSubmit}
                formInitialValues={formInitialValues}
                loading={posting}
            />
        </div>
    );
};

export default EditDistributorContainer;