import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import * as actions from '../actions';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import HEADER_FUNCTIONS from '../utils/functions/headers';
import {history} from '../store/store';

const REQUEST_DISTRIBUTORS = 'requestDistributors';
const REQUEST_CREATE_DISTRIBUTOR = 'requestCreateDistributor';
const REQUEST_PATCH_DISTRIBUTOR = 'requestPatchDistributor';
const REQUEST_FETCH_DISTRIBUTOR = 'requestFetchDistributor';
const REQUEST_DELETE_DISTRIBUTOR = 'requestDeleteDistributor';

export function redirectAfterDistributorCreateSuccess(sources) {
    const action$ = sources.ACTION
        .filter(action => (
            action.type === ActionTypes.CREATE_NEW_DISTRIBUTOR_SUCCESS
            || action.type === ActionTypes.EDIT_DISTRIBUTOR_SUCCESS
        ))
        .map(action => {
            history.push('/distributors');
            return actions.redirectAfterDistributorCreateEditSuccess();
        });

    return {
        ACTION: action$,
    };
}

export function requestFetchDistributor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_DISTRIBUTOR_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Distributor/ReadDistributor/${action.payload}`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_FETCH_DISTRIBUTOR,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_DISTRIBUTOR)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchDistributorByidSuccess(response)
                : actions.fetchDistributorByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestDeleteDistributor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.DELETE_DISTRIBUTOR_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Distributor/DeleteDistributor`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_DELETE_DISTRIBUTOR,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_DELETE_DISTRIBUTOR)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.deleteDistributorByidSuccess(response)
                : actions.deleteDistributorByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestPatchDistributor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.EDIT_DISTRIBUTOR)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Distributor/UpdateDistributor`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PATCH_DISTRIBUTOR,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PATCH_DISTRIBUTOR)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.editDistributorSuccess(response)
                : actions.editDistributorFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestCreateDistributor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.CREATE_NEW_DISTRIBUTOR)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Distributor/CreateDistributor`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_CREATE_DISTRIBUTOR,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_DISTRIBUTOR)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 201
                ? actions.createNewDistributorSuccess(response)
                : actions.createNewDistributorFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}


export function requestDistributors(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_DISTRIBUTORS)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Distributor/ReadAllDistributors`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_DISTRIBUTORS,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_DISTRIBUTORS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchDistributorsSuccess(response)
                : actions.fetchDistributorsFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
