import React, {useCallback} from 'react';
import DistributorsForm from '../../components/distributorsForm/distributorsForm';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';

const CreateDistributorContainer = (props) => {
    const {} = props;
    const posting = useSelector(state => state.distributors.posting);

    const dispatch = useDispatch();

    const createDistributorAction = useCallback(
        (payload) => dispatch(actions.createNewDistributor(payload)),
        [dispatch],
    );


    const handleSubmit = values => {
        createDistributorAction(values);
    };

    return (
        <div>
            <DistributorsForm
                title={'Create New Distributor'}
                submitLabel={'Create'}
                handleSubmit={handleSubmit}
                loading={posting}
            />
        </div>
    );
};

export default CreateDistributorContainer;