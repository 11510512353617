import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import * as actions from '../actions';
import GENERAL_FUNCTIONS from '../utils/functions/functions';
import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import HEADER_FUNCTIONS from '../utils/functions/headers';
import {history} from '../store/store';

const REQUEST_PRODUCERS = 'requestProducers';
const REQUEST_CREATE_PRODUCER = 'requestCreateProducer';
const REQUEST_PATCH_PRODUCER = 'requestPatchProducer';
const REQUEST_FETCH_PRODUCER = 'requestFetchProducer';
const REQUEST_DELETE_PRODUCER = 'requestDeleteProducer';

export function redirectAfterProducerCreateSuccess(sources) {
    const action$ = sources.ACTION
        .filter(action => (
            action.type === ActionTypes.CREATE_NEW_PRODUCER_SUCCESS
            || action.type === ActionTypes.EDIT_PRODUCER_SUCCESS
        ))
        .map(action => {
            history.push('/producers');
            return actions.redirectAfterProducerCreateEditSuccess();
        });

    return {
        ACTION: action$,
    };
}

export function requestFetchProducer(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PRODUCER_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Producer/ReadProducer/${action.payload}`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_FETCH_PRODUCER,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_PRODUCER)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchProducerByidSuccess(response)
                : actions.fetchProducerByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestDeleteProducer(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.DELETE_PRODUCER_BY_ID)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Producer/DeleteProducer`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_DELETE_PRODUCER,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_DELETE_PRODUCER)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.deleteProducerByidSuccess(response)
                : actions.deleteProducerByidFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestPatchProducer(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.EDIT_PRODUCER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Producer/UpdateProducer`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PATCH_PRODUCER,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PATCH_PRODUCER)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.editProducerSuccess(response)
                : actions.editProducerFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestCreateProducer(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.CREATE_NEW_PRODUCER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Producer/CreateProducer`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_CREATE_PRODUCER,
                method: 'POST',
                send: action.payload,
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_PRODUCER)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 201
                ? actions.createNewProducerSuccess(response)
                : actions.createNewProducerFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestProducers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.applicationUser.accessToken);

    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.FETCH_PRODUCERS)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
                url: `${GENERAL_FUNCTIONS.getBaseURL()}/Producer/ReadAllProducers`,
                headers: HEADER_FUNCTIONS.headersWithTokenJson(token),
                category: REQUEST_PRODUCERS,
                method: 'GET',
            }),
        );

    let httpResponse$ = sources.HTTP.select(REQUEST_PRODUCERS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response =>
            response.status === 200
                ? actions.fetchProducersSuccess(response)
                : actions.fetchProducersFailed(response),
        );

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
