import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import {useParams} from 'react-router-dom';
import PackagesForm from '../../components/packagesForm/packagesForm';
import packagesProjections from '../../projections/packages';

const EditPackageContainer = (props) => {
    const {} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const formInitialValues = useSelector(packagesProjections.packageFormikInitialValues(id));
    const posting = useSelector(state => state.packages.posting);

    const editPackageAction = useCallback(
        (payload) => dispatch(actions.editPackage(payload)),
        [dispatch],
    );

    const fetchPackageAction = useCallback(
        (payload) => dispatch(actions.fetchPackageByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchPackageAction(id);
    }, [id]);

    const handleSubmit = values => {
        editPackageAction(values);
    };

    return (
        <div>
            <PackagesForm
                disabledFieldKeys={['nfcId']}
                title={'Edit Package'}
                submitLabel={'Edit'}
                handleSubmit={handleSubmit}
                formInitialValues={formInitialValues}
                loading={posting}
            />
        </div>
    );
};

export default EditPackageContainer;