import {createSelector} from '@reduxjs/toolkit';

const packagesProjections = {};

packagesProjections.packages = createSelector(
    state => state.packages.packages.byId,
    packagesById => {
        return Object
            .values(packagesById)
            .map(packageById => {
                return {
                    id: packageById.id,
                    code: packageById.code,
                    nfcId: packageById.nfcId,
                    description: packageById.description,
                    pack: packageById.pack,
                };
            });
    },
);

packagesProjections.packageFormikInitialValues = packageId => createSelector(
    state => state.packages.packages.byId[packageId],
    packageById => {
        if(!packageById) {
            return  {};
        }

        return {
            id: packageById.id,
            code: packageById.code,
            nfcId: packageById.nfcId,
            productId: packageById.productId,
            description: packageById.description,
            pack: packageById.pack,
        };
    },
);


export default packagesProjections;
