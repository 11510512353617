import React from 'react';
import {Button, Heading, Table} from 'arag-ui-lib';
import { useHistory } from 'react-router-dom';

const columns = [
    {
        propertyName: 'code',
        header: 'Code',
        id: 'code',
        sortable: false,
    },
    {
        propertyName: 'pack',
        header: 'Pack',
        id: 'pack',
        sortable: false,
    },
    {
        propertyName: 'nfcId',
        header: 'Nfc Id',
        id: 'nfcId',
        sortable: false,
    },
    {
        propertyName: 'description',
        header: 'Description',
        id: 'description',
        sortable: false,
    },
];

const Packages = (props) => {
    const {loading, packages, handleDelete} = props;
    let history = useHistory();

    return (
        <div>
            <Heading variant="h2" title="Packages"/>
            <div className={'form-group'}>
                <Button
                    variant="primary"
                    label="Create New Package"
                    arrowDirection="right"
                    onClick={() => {
                        history.push('/new-package');
                    }}
                />
            </div>
            <Table
                loading={loading}
                striped
                columns={columns}
                data={packages}
                hasActions
                actions={
                    [
                        {
                            key: 'edit',
                            label: 'Edit',
                            callback: (datum) =>  history.push(`/packages/${datum.nfcId}`),
                        },
                        {
                            key: 'delete',
                            label: 'Delete',
                            callback: (datum) =>  handleDelete(datum.nfcId),
                        },
                    ]
                }
                // accordionItemHeaderFormatter={AccordionHeader}
                // pageSize={pager.limit}
                // totalRecords={pager.count || 0}
                // totalPages={pager.totalPages || 0}
                // page={pager.page}
                // pageSizeOptions={config.listSizeOptions}
                // goAtPrevious={goAtPrevious}
                // goAtNext={goAtNext}
                // goAtStart={goAtStart}
                // goAtEnd={goAtEnd}
                // goAtPage={goAtPage}
                // onPageSizeChange={onPageSizeChange}
            />
        </div>
    );
};

export default Packages;
