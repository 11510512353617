import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import MainRouter from './routers/mainRouter';
import { PersistGate } from 'redux-persist/integration/react';
import {persistor} from './store/store';

function App() {
  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <MainRouter/>
          </PersistGate>
      </Provider>
  );
}

export default App;
