// applicationUser
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const LOGOUT = 'LOGOUT';
export const GET_APPLICATION_USER_DETAILS = 'GET_APPLICATION_USER_DETAILS';
export const GET_APPLICATION_USER_DETAILS_SUCCESS = 'GET_APPLICATION_USER_DETAILS_SUCCESS';
export const GET_APPLICATION_USER_DETAILS_FAILED = 'GET_APPLICATION_USER_DETAILS_FAILED';

// message
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

// distributor
export const FETCH_DISTRIBUTORS = 'FETCH_DISTRIBUTORS';
export const FETCH_DISTRIBUTORS_SUCCESS = 'FETCH_DISTRIBUTORS_SUCCESS';
export const FETCH_DISTRIBUTORS_FAILED = 'FETCH_DISTRIBUTORS_FAILED';
export const CREATE_NEW_DISTRIBUTOR = 'CREATE_NEW_DISTRIBUTOR';
export const CREATE_NEW_DISTRIBUTOR_SUCCESS = 'CREATE_NEW_DISTRIBUTOR_SUCCESS';
export const CREATE_NEW_DISTRIBUTOR_FAILED = 'CREATE_NEW_DISTRIBUTOR_FAILED';
export const EDIT_DISTRIBUTOR = 'EDIT_DISTRIBUTOR';
export const EDIT_DISTRIBUTOR_SUCCESS = 'EDIT_DISTRIBUTOR_SUCCESS';
export const EDIT_DISTRIBUTOR_FAILED = 'EDIT_DISTRIBUTOR_FAILED';
export const FETCH_DISTRIBUTOR_BY_ID = 'FETCH_DISTRIBUTOR_BY_ID';
export const FETCH_DISTRIBUTOR_BY_ID_SUCCESS = 'FETCH_DISTRIBUTOR_BY_ID_SUCCESS';
export const FETCH_DISTRIBUTOR_BY_ID_FAILED = 'FETCH_DISTRIBUTOR_BY_ID_FAILED';
export const DELETE_DISTRIBUTOR_BY_ID = 'DELETE_DISTRIBUTOR_BY_ID';
export const DELETE_DISTRIBUTOR_BY_ID_SUCCESS = 'DELETE_DISTRIBUTOR_BY_ID_SUCCESS';
export const DELETE_DISTRIBUTOR_BY_ID_FAILED = 'DELETE_DISTRIBUTOR_BY_ID_FAILED';

// producer
export const FETCH_PRODUCERS = 'FETCH_PRODUCERS';
export const FETCH_PRODUCERS_SUCCESS = 'FETCH_PRODUCERS_SUCCESS';
export const FETCH_PRODUCERS_FAILED = 'FETCH_PRODUCERS_FAILED';
export const CREATE_NEW_PRODUCER = 'CREATE_NEW_PRODUCER';
export const CREATE_NEW_PRODUCER_SUCCESS = 'CREATE_NEW_PRODUCER_SUCCESS';
export const CREATE_NEW_PRODUCER_FAILED = 'CREATE_NEW_PRODUCER_FAILED';
export const EDIT_PRODUCER = 'EDIT_PRODUCER';
export const EDIT_PRODUCER_SUCCESS = 'EDIT_PRODUCER_SUCCESS';
export const EDIT_PRODUCER_FAILED = 'EDIT_PRODUCER_FAILED';
export const FETCH_PRODUCER_BY_ID = 'FETCH_PRODUCER_BY_ID';
export const FETCH_PRODUCER_BY_ID_SUCCESS = 'FETCH_PRODUCER_BY_ID_SUCCESS';
export const FETCH_PRODUCER_BY_ID_FAILED = 'FETCH_PRODUCER_BY_ID_FAILED';
export const DELETE_PRODUCER_BY_ID = 'DELETE_PRODUCER_BY_ID';
export const DELETE_PRODUCER_BY_ID_SUCCESS = 'DELETE_PRODUCER_BY_ID_SUCCESS';
export const DELETE_PRODUCER_BY_ID_FAILED = 'DELETE_PRODUCER_BY_ID_FAILED';

// product
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const CREATE_NEW_PRODUCT = 'CREATE_NEW_PRODUCT';
export const CREATE_NEW_PRODUCT_SUCCESS = 'CREATE_NEW_PRODUCT_SUCCESS';
export const CREATE_NEW_PRODUCT_FAILED = 'CREATE_NEW_PRODUCT_FAILED';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED';
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILED = 'FETCH_PRODUCT_BY_ID_FAILED';
export const DELETE_PRODUCT_BY_ID = 'DELETE_PRODUCT_BY_ID';
export const DELETE_PRODUCT_BY_ID_SUCCESS = 'DELETE_PRODUCT_BY_ID_SUCCESS';
export const DELETE_PRODUCT_BY_ID_FAILED = 'DELETE_PRODUCT_BY_ID_FAILED';

// package
export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_FAILED = 'FETCH_PACKAGES_FAILED';
export const CREATE_NEW_PACKAGE = 'CREATE_NEW_PACKAGE';
export const CREATE_NEW_PACKAGE_SUCCESS = 'CREATE_NEW_PACKAGE_SUCCESS';
export const CREATE_NEW_PACKAGE_FAILED = 'CREATE_NEW_PACKAGE_FAILED';
export const EDIT_PACKAGE = 'EDIT_PACKAGE';
export const EDIT_PACKAGE_SUCCESS = 'EDIT_PACKAGE_SUCCESS';
export const EDIT_PACKAGE_FAILED = 'EDIT_PACKAGE_FAILED';
export const FETCH_PACKAGE_BY_ID = 'FETCH_PACKAGE_BY_ID';
export const FETCH_PACKAGE_BY_ID_SUCCESS = 'FETCH_PACKAGE_BY_ID_SUCCESS';
export const FETCH_PACKAGE_BY_ID_FAILED = 'FETCH_PACKAGE_BY_ID_FAILED';
export const DELETE_PACKAGE_BY_ID = 'DELETE_PACKAGE_BY_ID';
export const DELETE_PACKAGE_BY_ID_SUCCESS = 'DELETE_PACKAGE_BY_ID_SUCCESS';
export const DELETE_PACKAGE_BY_ID_FAILED = 'DELETE_PACKAGE_BY_ID_FAILED';



// general
export const REDIRECT_AFTER_CREATE_EDIT_SUCCESS = 'REDIRECT_AFTER_CREATE_EDIT_SUCCESS';