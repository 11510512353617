import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import {useParams} from 'react-router-dom';
import producerProjections from '../../projections/producers';
import ProducersForm from '../../components/producersForm/producersForm';

const EditProducerContainer = (props) => {
    const {} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const formInitialValues = useSelector(producerProjections.producerFormikInitialValues(id));
    const posting = useSelector(state => state.producers.posting);

    const editProducerAction = useCallback(
        (payload) => dispatch(actions.editProducer(payload)),
        [dispatch],
    );

    const fetchProducerAction = useCallback(
        (payload) => dispatch(actions.fetchProducerByid(payload)),
        [dispatch],
    );

    useEffect(() => {
        fetchProducerAction(id);
    }, [id]);

    const handleSubmit = values => {
        editProducerAction(values);
    };

    return (
        <div>
            <ProducersForm
                disabledFieldKeys={['legalName']}
                title={'Edit Producer'}
                submitLabel={'Edit'}
                handleSubmit={handleSubmit}
                formInitialValues={formInitialValues}
                loading={posting}
            />
        </div>
    );
};

export default EditProducerContainer;