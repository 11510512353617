import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const fetchProducers = createAction(ActionTypes.FETCH_PRODUCERS);
export const fetchProducersSuccess = createAction(ActionTypes.FETCH_PRODUCERS_SUCCESS);
export const fetchProducersFailed = createAction(ActionTypes.FETCH_PRODUCERS_FAILED);

export const createNewProducer = createAction(ActionTypes.CREATE_NEW_PRODUCER);
export const createNewProducerSuccess = createAction(ActionTypes.CREATE_NEW_PRODUCER_SUCCESS);
export const createNewProducerFailed = createAction(ActionTypes.CREATE_NEW_PRODUCER_FAILED);

export const editProducer = createAction(ActionTypes.EDIT_PRODUCER);
export const editProducerSuccess = createAction(ActionTypes.EDIT_PRODUCER_SUCCESS);
export const editProducerFailed = createAction(ActionTypes.EDIT_PRODUCER_FAILED);

export const fetchProducerByid = createAction(ActionTypes.FETCH_PRODUCER_BY_ID);
export const fetchProducerByidSuccess = createAction(ActionTypes.FETCH_PRODUCER_BY_ID_SUCCESS);
export const fetchProducerByidFailed = createAction(ActionTypes.FETCH_PRODUCER_BY_ID_FAILED);

export const deleteProducerByid = createAction(ActionTypes.DELETE_PRODUCER_BY_ID);
export const deleteProducerByidSuccess = createAction(ActionTypes.DELETE_PRODUCER_BY_ID_SUCCESS);
export const deleteProducerByidFailed = createAction(ActionTypes.DELETE_PRODUCER_BY_ID_FAILED);

export const redirectAfterProducerCreateEditSuccess = createAction(ActionTypes.REDIRECT_AFTER_CREATE_EDIT_SUCCESS);
