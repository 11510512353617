import * as ActionTypes from '../actions/types';
import { v4 } from 'uuid';
import { createReducer } from '@reduxjs/toolkit';
import config from '../utils/constants/config';

const createMessage = (type, message, description = null) => {
    const newId = v4();
    return {
        type: type,
        uuid: newId,
        message: message,
        created: new Date(),
        description: description,
    };
};

const INITIAL_STATE = {
    byId: {},
    allIds: [],
};

const addSuccessMessage = (initialMessage = 'Action was successful') =>  (state, action) => {
    const message = createMessage(config.StatusMessage.SUCCESS, initialMessage);

    return {
        ...state,
        byId: {
            ...state.byId,
            [message.uuid]: message,
        },
        allIds: [
            ...state.allIds,
            message.uuid,
        ],
    };
};

const addErrorMessage = initialMessage => (state, action) => {
    let text = 'Action failed';
    if(
        action.payload
        && action.payload.response
        && action.payload.response.body
        && action.payload.response.body.errors
        && action.payload.response.body.errors.filter(error => error).map(error => error).length > 0
    ) {
        text = action.payload.response.body.errors.map(error => error);
    } else if (
        action.payload
        && action.payload
        && action.payload.message
    ){
        text = action.payload.message;
    } else if (initialMessage){
        text = initialMessage;
    }

    const message = createMessage(config.StatusMessage.ERROR, text);
    return {
        ...state,
        byId: {
            ...state.byId,
            [message.uuid]: message,
        },
        allIds: [
            ...state.allIds,
            message.uuid,
        ],
    };
};

const addWarningMessage = initialMessage => (state, action) =>{
    const message = createMessage(config.StatusMessage.WARNING, initialMessage);
    return {
        ...state,
        byId: {
            ...state.byId,
            [message.uuid]: message,
        },
        allIds: [
            ...state.allIds,
            message.uuid,
        ],
    };

};

const removeMessage = (state, action) => {
    const  { [action.payload.uuid]: value, ...newMessages }  = state.byId;

    return {
        ...state,
        byId: {
            ...newMessages,
        },
        allIds: [
            ...Object
                .entries(newMessages)
                .map(([k, v]) => v.uuid),
        ],
    };
};

const message = createReducer(INITIAL_STATE, {
    [ActionTypes.REMOVE_MESSAGE]: removeMessage,
    [ActionTypes.ADD_ERROR_MESSAGE]:  addErrorMessage(),

    [ActionTypes.FETCH_DISTRIBUTORS_FAILED]:  addErrorMessage('Failed to fetch distributors'),
    [ActionTypes.FETCH_DISTRIBUTOR_BY_ID_FAILED]:  addErrorMessage('Failed to fetch distributor'),
    [ActionTypes.CREATE_NEW_DISTRIBUTOR_FAILED]:  addErrorMessage('Failed to create new distributor'),
    [ActionTypes.EDIT_DISTRIBUTOR_FAILED]:  addErrorMessage('Failed to edit distributor'),
    [ActionTypes.DELETE_DISTRIBUTOR_BY_ID_FAILED]:  addErrorMessage('Failed to delete distributor'),

    [ActionTypes.FETCH_PRODUCERS_FAILED]:  addErrorMessage('Failed to fetch producers'),
    [ActionTypes.FETCH_PRODUCER_BY_ID_FAILED]:  addErrorMessage('Failed to fetch producer'),
    [ActionTypes.CREATE_NEW_PRODUCER_FAILED]:  addErrorMessage('Failed to create producer'),
    [ActionTypes.EDIT_PRODUCER_FAILED]:  addErrorMessage('Failed to edit producer'),
    [ActionTypes.DELETE_PRODUCER_BY_ID_FAILED]:  addErrorMessage('Failed to delete producer'),

    [ActionTypes.FETCH_PRODUCTS_FAILED]:  addErrorMessage('Failed to fetch products'),
    [ActionTypes.FETCH_PRODUCT_BY_ID_FAILED]:  addErrorMessage('Failed to fetch product by id'),
    [ActionTypes.CREATE_NEW_PRODUCT_FAILED]:  addErrorMessage('Failed to create product'),
    [ActionTypes.EDIT_PRODUCT_FAILED]:  addErrorMessage('Failed to edit product'),
    [ActionTypes.DELETE_PRODUCT_BY_ID_FAILED]:  addErrorMessage('Failed to delete product'),

    [ActionTypes.FETCH_PACKAGES_FAILED]:  addErrorMessage('Failed to fetch packages'),
    [ActionTypes.FETCH_PACKAGE_BY_ID_FAILED]:  addErrorMessage('Failed to fetch package by id'),
    [ActionTypes.CREATE_NEW_PACKAGE_FAILED]:  addErrorMessage('Failed to create package'),
    [ActionTypes.EDIT_PACKAGE_FAILED]:  addErrorMessage('Failed to edit package'),
    [ActionTypes.DELETE_PACKAGE_BY_ID_FAILED]:  addErrorMessage('Failed to delete package'),

    [ActionTypes.LOGIN_FAILED]:  addErrorMessage('Login failed'),

});

export default message;