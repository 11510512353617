import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DistributorsContainer from '../containers/distributors/distributorsContainer';
import CreateDistributorContainer from '../containers/distributors/createDistributorContainer';
import EditDistributorContainer from '../containers/distributors/editDistributorContainer';
import CreateProducerContainer from '../containers/producers/createProducerContainer';
import EditProducerContainer from '../containers/producers/editProducerContainer';
import ProducersContainer from '../containers/producers/producersContainer';
import CreateProductContainer from '../containers/products/createProductContainer';
import EditProductContainer from '../containers/products/editProductContainer';
import ProductsContainer from '../containers/products/productsContainer';
import CreatePackageContainer from '../containers/packages/createPackageContainer';
import EditPackageContainer from '../containers/packages/editPackageContainer';
import PackagesContainer from '../containers/packages/packagesContainer';


const ContentRouter = (props) => {
    return (
        <Switch>
            <Route path="/new-distributor" component={CreateDistributorContainer}/>
            <Route path="/distributors/:id" component={EditDistributorContainer}/>
            <Route path="/distributors" component={DistributorsContainer}/>
            <Route path="/new-producer" component={CreateProducerContainer}/>
            <Route path="/producers/:id" component={EditProducerContainer}/>
            <Route path="/producers" component={ProducersContainer}/>
            <Route path="/new-product" component={CreateProductContainer}/>
            <Route path="/products/:id" component={EditProductContainer}/>
            <Route path="/products" component={ProductsContainer}/>
            <Route path="/new-package" component={CreatePackageContainer}/>
            <Route path="/packages/:id" component={EditPackageContainer}/>
            <Route path="/packages" component={PackagesContainer}/>
            <Route path="/" render={() => <Redirect to={'/distributors'}/>}/>
        </Switch>
    );
};

export default ContentRouter;