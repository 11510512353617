import rules from '../constants/rules';
import moment from 'moment';
import config from '../constants/config';

const GENERAL_FUNCTIONS = {};

GENERAL_FUNCTIONS.getBaseURL = () => {
    return process.env.REACT_APP_BASE_URL + 'api/v1';
};

GENERAL_FUNCTIONS.getInputItem = (variant) => {
    if (
        variant === 'text-input'
        || variant === 'number'
    ) {
        return 'text';
    }

    if (variant === 'text-area') {
        return 'textarea';
    }

    if (
        variant === 'date'
        || variant === 'date-time'
        || variant === 'time'
    ) {
        return 'date';
    }

    if (variant === 'checkbox') {
        return 'checkbox';
    }

    if (variant === 'list') {
        return 'select';
    }

    if (variant === 'amount') {
        return 'currency';
    }

    if (variant === 'post-code') {
        return 'asyncSearchableList';
    }

    if (variant === 'range') {
        return 'select';
    }

    return 'text';
};

GENERAL_FUNCTIONS.validationCreator = question => questionValidationRules => value => {
    if (!questionValidationRules) {
        return null;
    }

    if (
        !question.isRequired
        && !value
    ) {
        return null;
    }

    const invalidRule = questionValidationRules.find(rule => {
        // regex described rule
        if (
            rule.regExExpression
        ) {
            // const regexString = GENERAL_FUNCTIONS
            //     .fromCSharpRegexString(rule.regExExpression);
            return RegExp(rule.regExExpression).test(value) === false;
        }

        // get question answer restrictions
        const valueRestrictions = question.questionValidationRules[rule.code];
        if (!valueRestrictions) {
            return false;
        }
        const firstValueRestriction = valueRestrictions.length > 0
            ? valueRestrictions[0]
            : null;

        const maxRestrictionValue = valueRestrictions
            .reduce((acc, curr) => curr > acc ? curr : acc, firstValueRestriction);
        const minRestrictionValue = valueRestrictions
            .reduce((acc, curr) => curr < acc ? curr : acc, firstValueRestriction);

        // handle min age validation rule
        if (rule.code.toLowerCase() === rules.minAge.code.toLowerCase()) {
            const diff = GENERAL_FUNCTIONS.getTimeDifference(new Date(), new Date(value));
            return diff.years < parseInt(maxRestrictionValue.value);
        }

        // handle max age validation rule
        if (rule.code.toLowerCase() === rules.maxAge.code.toLowerCase()) {
            const diff = GENERAL_FUNCTIONS.getTimeDifference(new Date(), new Date(value));
            return diff.years > parseInt(minRestrictionValue.value);
        }

        // handle min value validation rule
        if (rule.code.toLowerCase() === rules.minValue.code.toLowerCase()) {
            return parseFloat(value) < parseFloat(maxRestrictionValue.value);
        }

        // handle max value validation rule
        if (rule.code.toLowerCase() === rules.maxValue.code.toLowerCase()) {
            return parseFloat(value) > parseFloat(minRestrictionValue.value);
        }

        // handle min length validation rule
        if (rule.code.toLowerCase() === rules.minLength.code.toLowerCase()) {
            return value && value.length < parseInt(maxRestrictionValue.value);
        }

        // handle max length validation rule
        if (rule.code.toLowerCase() === rules.maxLength.code.toLowerCase()) {
            return value && value.length > parseInt(minRestrictionValue.value);
        }

        // handle future date validation rule
        if (rule.code.toLowerCase() === rules.futureDate.code.toLowerCase()) {
            return moment().isSameOrAfter(moment(value), 'day');
        }

        //handle past-date validation rule
        if (rule.code.toLowerCase() === rules.pastDate.code.toLowerCase()) {
            return moment().isSameOrBefore(moment(value), 'day');
        }

        // handle today - future date validation rule
        if (rule.code.toLowerCase() === rules.todayFutureDate.code.toLowerCase()) {
            return moment().isAfter(moment(value), 'day');
        }

        // handle today - past date validation rule
        if (rule.code.toLowerCase() === rules.todayPastDate.code.toLowerCase()) {
            return moment().isBefore(moment(value), 'day');
        }

        return false;
    });

    let message = null;
    if (invalidRule) {
        if (rules.minAge.code.toLowerCase() === invalidRule.code
            || rules.maxAge.code.toLowerCase() === invalidRule.code
            || rules.maxLength.code.toLowerCase() === invalidRule.code
            || rules.minLength.code.toLowerCase() === invalidRule.code
        ) {
            message = {
                errorMessage: question && question.requiredErrorMessage
                    ? question.requiredErrorMessage
                    : null,
            };
        } else {

            message = (
                question.questionValidationRules
                && question.questionValidationRules[invalidRule.code]
                && question.questionValidationRules[invalidRule.code].length > 0
            )
                ? question.questionValidationRules[invalidRule.code].find(item => item.errorMessage)
                : null;
        }
    }

    return invalidRule ?
        message
        && message.errorMessage
            ? message.errorMessage
            : question && question.requiredErrorMessage
            ? question.requiredErrorMessage
            : invalidRule.description : null;

};

GENERAL_FUNCTIONS.getTimeDifference = (firstDate, secondDate) => {
    const elapsedTimeFromNow = Math.abs(firstDate - secondDate);
    const elapsedTimeInYears = (elapsedTimeFromNow / 1000 / 60 / 60 / 24) / 365.25;

    return {
        years: elapsedTimeInYears,
    };
};

GENERAL_FUNCTIONS.fromCSharpRegexString = (regexString) => {
    if (!regexString) return null;

    if (
        regexString
        && regexString.length > 3
        && regexString[0] === '@'
        && regexString[1] === '"'
        && regexString[regexString.length - 1] === '"'
    ) {
        return regexString.slice(2, regexString.length - 1);
    }

    return regexString;
};

GENERAL_FUNCTIONS.readFileAsBase64Async = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.substring(reader.result.indexOf('base64,') + 7));
        };

        reader.onerror = reject;
    });
};

GENERAL_FUNCTIONS.downLoadFile = (name, base64Encoding) => {
    const src = `data:application/binary;base64,${base64Encoding}`;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const byteCharacters = atob(base64Encoding);
        const byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/binary' });
        window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        const fileName = name;
        downloadLink.href = src;
        downloadLink.download = fileName;
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
};

GENERAL_FUNCTIONS.fetchAddressesByPostcodeService = async (value, name) => {
    const result = await fetch(`https://pcls1.craftyclicks.co.uk/json/rapidaddress?key=ef8cd-eef06-0f340-02a0fJ&response=data_formatted&sort=asc&lines=3&postcode=${value}`);
    const data = await result.json();

    let addresses = data &&
    data.delivery_points &&
    data.delivery_points.length > 0 ?
        data.delivery_points.map(x => {

            let label = GENERAL_FUNCTIONS.toTitleCase(x.line_1);
            if (x.line_2 != null && !GENERAL_FUNCTIONS.isUndefined(x.line_2) && x.line_2.length > 0)
                label += `, ${GENERAL_FUNCTIONS.toTitleCase(x.line_2)}`;
            if (x.line_3 != null && !GENERAL_FUNCTIONS.isUndefined(x.line_3) && x.line_3.length > 0)
                label += `, ${GENERAL_FUNCTIONS.toTitleCase(x.line_3)}`;
            return {
                label: label,
                value: {
                    line1: x.line_1 ? GENERAL_FUNCTIONS.toTitleCase(x.line_1) : '',
                    line2: x.line_2 ? GENERAL_FUNCTIONS.toTitleCase(x.line_2) : '',
                    line3: x.line_3 ? GENERAL_FUNCTIONS.toTitleCase(x.line_3) : '',
                },
                postcode: data.postcode ? data.postcode : '',
                county: data.postal_county ? GENERAL_FUNCTIONS.toTitleCase(data.postal_county) : '',
                town: data.town ? GENERAL_FUNCTIONS.toTitleCase(data.town) : '',
            };
        }) : [];

    return addresses.map((item, index) => ({
        value: index,
        label: item.label,
        lines: item.value,
        postcode: item.postcode,
        county: item.county,
        town: item.town,
    }));

};

GENERAL_FUNCTIONS.datURLtoFile = (dataurl, filename, fileType) => {
    let bstr = atob(dataurl),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: fileType });
};

GENERAL_FUNCTIONS.scaleImage = async (encodedImage, fileType, scaleFactor) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const scaledWidth = img.width * scaleFactor;
            const scaledHeight = img.height * scaleFactor;
            const canvasEl = document.createElement('canvas');
            canvasEl.width = scaledWidth;
            canvasEl.height = scaledHeight;
            const ctx = canvasEl.getContext('2d');
            ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
            const dataUrlString = ctx.canvas.toDataURL(fileType, 1);
            resolve(
                dataUrlString.substring(dataUrlString.indexOf('base64,') + 7),
            );
            img.remove();
            canvasEl.remove();
        };
        img.src = `data:${fileType};base64,${encodedImage}`;
    });

};

GENERAL_FUNCTIONS.base64FileSizeInBytes = (base64String) => {
    return 4 * Math.ceil((base64String.length / 3)) * 0.5624896334383812;
};

GENERAL_FUNCTIONS.compressImageDimensions = async (imageFile, sizeLimit = config.MAX_ALLOWED_SIZE_IN_BYTES) => {
    let iterativeEncodedImage = await GENERAL_FUNCTIONS.readFileAsBase64Async(imageFile);
    let totalIter = 1;
    while (GENERAL_FUNCTIONS.base64FileSizeInBytes(iterativeEncodedImage) > sizeLimit) {
        iterativeEncodedImage = await GENERAL_FUNCTIONS.scaleImage(iterativeEncodedImage, imageFile.type, 1 / (Math.pow(2, totalIter)));
        totalIter = totalIter + 1;
    }
    return iterativeEncodedImage;
};

GENERAL_FUNCTIONS.extractDataFromResponseObject = (response) => {
    return (
        response
        && response.body
        && response.body.data
    )
        ? response.body.data
        : null;
};

GENERAL_FUNCTIONS.mergeArraysOfObjects = (key, ...arraysWithObjects) => {
    const map = {};
    arraysWithObjects.forEach(arg => {
        arg.forEach(item => map[item[key]] = item );
    });

    return Object.values(map).map(item => item);
};

GENERAL_FUNCTIONS.toTitleCase = (string) => {
    string = string.toLowerCase().split(' ');
    for (var i = 0; i < string.length; i++) {
        string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(' ');
};

GENERAL_FUNCTIONS.isUndefined = (v) => {
    return typeof v === 'undefined';
};

GENERAL_FUNCTIONS.normalizeById = (id, entity) => {
    if(!entity) {
        return  {byId: {}, allIds: []};
    }

    let byId = {};
    let allIds = [];

    entity.forEach(item => {
        if(item) {
            byId = {
                ...byId,
                [item[id]]: item,
            };
            allIds.push(item[id]);
        };
    });

    return {
        byId: byId,
        allIds: allIds,
    };

};

GENERAL_FUNCTIONS.mergeArraysUnique = (...input) => {
    const merged = [].concat(...input);
    const newSet = new Set(merged);
    return [...newSet];
};

GENERAL_FUNCTIONS.rangeArray = (start, end) => {
    const range = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }

    return range;
};

GENERAL_FUNCTIONS.createUrlQueryParameters = (params) => {
    return Object
        .entries(params)
        .reduce((acc, [key, value], index) => {
            if(!key || !value){
                return acc;
            }

            const constructedQueryParam = GENERAL_FUNCTIONS.constructSingleQueryParameter({key, value});

            if(index === 0){
                return acc + `?${constructedQueryParam}`;
            }

            return acc + `&${constructedQueryParam}`;
        }, '');
};

GENERAL_FUNCTIONS.constructSingleQueryParameter = ({key, value}) => {
    if(!key || !value){
        return '';
    }

    if(Array.isArray(value)){
        return value
            .reduce((acc, curr, index) => {
                if(index === 0) {
                    return acc + `${key}=${curr}`;
                }

                return acc + `&${key}=${curr}`;
            }, '');
    }

    return `${key}=${value}`;
};

GENERAL_FUNCTIONS.appendCurrencySign = (amount, currency) => {
    if(!currency){
        return amount;
    }

    if(currency.position === config.currencyPosition.after){
        return `${amount} ${currency.sign}`;
    }

    if(currency.position === config.currencyPosition.before){
        return `${currency.sign} ${amount}`;
    }

    return  amount;
};

// calculates the total number of differences between two objects (it takes account only first level
// and (second level for arrays))


GENERAL_FUNCTIONS.numberOfDiffsBetweenTwoObjects = (firstObject, secondObject) => {
    if(!firstObject && !secondObject){
        return 0;
    }

    if(firstObject && !secondObject){
        return  Object.keys(firstObject).length;
    }

    if(!firstObject && secondObject){
        return  Object.keys(secondObject).length;
    }


    let objectWithMostItems = {}, objectWithLessItems = {};
    if(Object.keys(firstObject).length > Object.keys(secondObject).length){
        objectWithMostItems = firstObject;
        objectWithLessItems = secondObject;
    } else if(
        Object.keys(firstObject).length < Object.keys(secondObject).length
    ) {
        objectWithMostItems = secondObject;
        objectWithLessItems = firstObject;
    } else {
        objectWithMostItems = firstObject;
        objectWithLessItems = secondObject;
    }

    const totalDiffs = Object.entries(objectWithMostItems)
        .reduce((acc, [key, value]) => {
            if(
                Array.isArray(value) === false
                && objectWithLessItems[key] !== value
            ){
                acc = acc + 1;
            } else if(
                Array.isArray(value) === true
                && value.some((v, index) => (
                    objectWithLessItems
                    && objectWithLessItems[key]
                    && v !== objectWithLessItems[key][index]
                ))
            ) {
                acc = acc + 1;
            }

            return acc;
        }, 0);

    return totalDiffs;
};

GENERAL_FUNCTIONS.editorOnInitInstance = (editor)=>{editor.getBody().setAttribute('contenteditable', false);}

GENERAL_FUNCTIONS.editorOnKeyDown = (evt,editor) => {
    const node = editor.selection.getNode();
    const range = editor.selection.getRng();
    const startOffset = range.startOffset;
    // if delete Keys pressed
    if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (startOffset === 0) {
            evt.preventDefault();
            node.innerHTML = "&nbsp;";
            evt.stopPropagation();
            return false;
        }
    }
};

GENERAL_FUNCTIONS.removeObjectPropertyByKeyValue = (targetObj, key, value) => {
    return Object
        .entries(targetObj)
        .reduce((acc, curr) => {
            const [currValue, currKey] = curr;
            if(curr[key] === value){
                return acc;
            }

            return {
                ...acc,
                [currKey]: currValue,
            };
        }, {});
};

export default GENERAL_FUNCTIONS;



