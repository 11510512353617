import {createSelector} from '@reduxjs/toolkit';

const applicationUserProjections = {};

applicationUserProjections.isLoggedIn = createSelector(
    state => state.applicationUser.accessToken,
    token => {
        return !!token;
    },
);

applicationUserProjections.applicationUserDisplayName = createSelector(
    state => state.applicationUser.details,
    appUserDetails => {
        if(!appUserDetails){
            return '';
        }

        return `${appUserDetails.forename ? appUserDetails.forename : ''} ${appUserDetails.surname ? appUserDetails.surname : ''}`;
    },
);


export default applicationUserProjections;