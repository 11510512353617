import React from 'react';
import Layout from '../../components/layout/layout';
import {useDispatch} from "react-redux";

const LayoutContainer = props => {
    const dispatch = useDispatch();

    return <Layout />;
};

export default LayoutContainer;
